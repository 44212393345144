// src/components/FinantareStartUp.js
import React from 'react';
import {Container, Typography} from "@mui/material";

function FinantareStartUp() {
    const css = `
    #root{
   display: grid;
   min-height: 100vh;
   
    };`
    return (
        <>
            <style>
                {css}
            </style>
        <Container maxWidth="lg">
            <h1>Finantare Start UP</h1>
            <Typography>
                Susţinerea investiţiilor realizate de către întreprinderi nou-înfiinţate şi micro-întreprinderi, precum şi a investiţiilor de modernizare şi retehnologizare a întreprinderilor mici şi mijlocii din sectoarele de producţie şi servicii, creşterea volumului de activitate şi a competitivităţii și sustenabilității întreprinderilor din aceste sectoare.
            </Typography>
            <Typography>
                Susţinerea investiţiilor realizate de către întreprinzătorii agricoli în agricultura românească, creşterea competitivităţii produselor agricole româneşti şi înfiinţarea de noi locuri de munca.
            </Typography>
        </Container>
        </>
    );
}

export default FinantareStartUp;
