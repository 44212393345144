import React, { useState } from 'react';
import {Button, Modal, Box, Typography} from '@mui/material';
import axios from 'axios';
const GoogleDocsModal = ({ buttonName, googleDocsLink }) => {
    const [open, setOpen] = useState(false);
    const [docContent, setDocContent] = useState('');

    const handleOpen = async () => {
        try {
            const response = await axios.get(googleDocsLink);
            const docHTML = response.data;
            // debugger;
            const parser = new DOMParser();
            const doc = parser.parseFromString(docHTML, 'text/html');
            //remove all max-width css styles using regex
            doc.querySelectorAll('style').forEach((style) => {
                style.innerHTML = style.innerHTML.replace(/max-width:.*?;/g, '');

            });
            setDocContent(doc.documentElement.innerHTML);
            setOpen(true);
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleOpen}><Typography sx={{color:'white',display:'block',textDecoration: 'underline',textTransform:"capitalize"}}>{buttonName}</Typography></Button>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "95vw",
                        maxHeight:"80vh",
                        bgcolor: 'white',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                    }}
                    >
                   <div dangerouslySetInnerHTML={{ __html: docContent }} />
                </Box>
            </Modal>
        </div>
    );
};

export default GoogleDocsModal;
