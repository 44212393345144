import React from 'react';
import {Grid, Typography} from "@mui/material";
import GoogleDocsModal from "./GoogleDocsModal";
import ContactForm from "./ContactForm";
function Footer(){
    const currentYear = new Date().getFullYear();
    return(
        <>

            <Grid container spacing={2} maxWidth={'sm'} sx={{margin:"0 auto",paddingTop:"60px"}}>

            <ContactForm/>
            </Grid>
            <div style={{paddingTop: '20px', paddingBottom: '20px', background:"#1d5056"}}>
        <Grid container spacing={2} maxWidth={'md'} sx={{margin:"0 auto"}}>
            <Grid item xs={12} md={12} sx={{
                "@media (max-width: 600px)": {
                    margin: 0,      // Remove margin
                    padding:"0 !important", // Adjust padding
                }}}>
                <Grid container spacing={3} sx={{justifyContent:"center",borderBottom:"1px solid white"}}>
                    <Grid item sx={{paddingTop:"0 !important"}}>
                        <GoogleDocsModal
                            buttonName="Politica de confidentialitate"
                            googleDocsLink="https://docs.google.com/document/d/e/2PACX-1vT9nwY0BprJ-vlldeMCrAiCXWGXb-GoVuwrTIn5uFywj8Y2YeUDwooN5uraDO7bVdzh6RNm12qXlld0/pub"
                        />
                    </Grid>
                    <Grid item sx={{paddingTop:"0 !important"}}>
                        <GoogleDocsModal
                            buttonName="Termeni & conditii"
                            googleDocsLink="https://docs.google.com/document/d/e/2PACX-1vTLrZFAZk42YCmBG0Kr_BgRhTDMoHYHg8cCz8OjSqkmizU4KCa7tM5fBzzqDk2SQfOR82lJuwfByMQc/pub"
                        />
                    </Grid>
                    <Grid item sx={{paddingTop:"0 !important"}}>
                        <GoogleDocsModal
                            buttonName="Politica de cookies"
                            googleDocsLink='https://docs.google.com/document/d/e/2PACX-1vSptjB9W0gzOp_lHeL3hcZsCFMG5IxRIERmdE1cX_OkULZDUq10Ik3mBWzvvneFNqdUzO7Tni3AWrOj/pub'
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12}  sx={{
                "@media (max-width: 600px)": {
                    margin: 0,      // Remove margin
                    padding:"0 !important", // Adjust padding
                }}}>
                <Grid container spacing={3} sx={{justifyContent:"space-between"}}>
                    <Grid item><Typography variant={'span'} sx={{color:'white',textTransform:'uppercase'}}>Copyright © {currentYear} <br/>
                        BANAT CONSULTANTA SRL. <br/> CIF: 4351314 / J18/832/1993 </Typography></Grid>
                    <Grid item sx={{
                        "@media (max-width: 600px)": {
                            width:"100%"
                        }}}><Typography variant={'span'} sx={{color:'white', }}>Designed by</Typography> <Typography component={'a'} sx={{color:"white"}} href={'//incognito.ro'}>Incognito Concept</Typography></Grid>
                </Grid>
            </Grid>
        </Grid>
            </div>
            </>
    )
}
export default Footer;