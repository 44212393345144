import './App.css';
import {useTheme} from '@mui/material/styles';
import {Button, Grid} from "@mui/material";
import {Box} from "@mui/material";
import {Container} from "@mui/material";
import {Typography} from "@mui/material";
import React, { useState, useEffect, useRef } from 'react';

import PromotionalChunk from "./PromotionalChunk";
import CreditCalculator  from "./CreditCalculator";

import bannerImagePNG from './images/NoPath.png';
import bannerImageWebP from './images/NoPath.webp';
import bannerImage2xPNG from './images/NoPath@2x.png';
import bannerImage2xWebP from './images/NoPath@2x.webp';

import textBoxImage2xPNG from './images/text-box-blue@2x.png';
import textBoxImage2xWebP from './images/text-box-blue@2x.webp';

import diagramImage2xPNG from './images/diagram-blue@2x.png';
import diagramImage2xWebP from './images/diagram-blue@2x.webp';

import targetBlueImageWebP from './images/target-blue.webp';
import targetBlueImage2xPNG from './images/target-blue@2x.png';

import calendarBlueImage2xPNG from './images/calendar-blue@2x.png';
import calendarBlueImage2xWebP from './images/calendar-blue@2x.webp';

import mapImagePNG from './images/t-map_name3.png';
import mapImageWebP from './images/t-map_name3.webp';
import mapImage2xPNG from './images/t-map_name3@2x.png';
import mapImage2xWebP from './images/t-map_name3@2x.webp';
import ButtonWithScroll from "./ButtonWithScroll";

const TextSection = () => {
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        padding: 0,
        margin: 0,
        borderRadius:"5px"
    };

    return (
        <Grid container maxWidth="lg" spacing={2} sx={{margin: "30px 0",}}>
            <Grid item xs={12} md={6} sx={{
                "@media (max-width: 600px)": {
                margin: 0,
                padding: "10px",
            }}}>
                <Typography variant='h1'>Refinanțează-ți creditul!</Typography>
                <Typography variant="p" component="p" sx={{flexGrow: 1}}>
                    Bine ați venit la refinanțare.online, locul unde vă putem ajuta să depășiți problemele financiare! Indiferent de dificultățile pe care le întâmpinați, suntem aici pentru a vă oferi
                    soluții și sprijin în gestionarea situației dumneavoastră financiare.
                </Typography>
                <Typography variant="p" component="p" sx={{flexGrow: 1}}>
                    La refinanțare.online, înțelegem că problemele financiare pot fi copleșitoare și pot afecta aspecte importante ale vieții dumneavoastră. Echipa noastră de experți în domeniul
                    financiar
                    este dedicată să vă ofere sfaturi și servicii personalizate pentru a vă ajuta să depășiți aceste obstacole.
                </Typography>
                <Typography variant="p" component="p" sx={{flexGrow: 1}}>
                    Indiferent dacă vă confruntați cu datorii, rate de dobândă ridicate, dificultăți în gestionarea bugetului sau alte probleme financiare, avem soluțiile potrivite pentru
                    dumneavoastră.
                    Lucrăm cu dumneavoastră pentru a înțelege în detaliu situația dumneavoastră financiară și vă oferim strategii și instrumente care să vă ajute să vă recăpătați stabilitatea
                    financiară.
                </Typography>
                <ButtonWithScroll sx={{fontSize: "14px", float: 'left', color: "white", textTransform: "capitalize", padding: "15px 45px", fontWeight: 700}}/>
            </Grid>
            <Grid item xs={12} md={6} sx={{
                "@media (max-width: 600px)": {
                    margin: 0,
                    padding: "10px",
                }}}>
                <picture>
                    <source srcSet={`${bannerImageWebP} 1x, ${bannerImage2xWebP} 2x`} type="image/webp" />
                    <source srcSet={`${bannerImagePNG} 1x, ${bannerImage2xPNG} 2x`} type="image/png" />
                    <img preload="true" width="568" height="482" style={imgStyle} src={bannerImagePNG} srcSet={`${bannerImagePNG} 1x, ${bannerImage2xPNG} 2x`} alt="imagine cu persoana semnand" />
                </picture>

            </Grid>
        </Grid>
    )
}

const StepSection = () => {

    const pStyle = {
        textAlign: "center",
        color: "#666666",
        fontSize: "14px",
        minHeight:"65px"
    }
    const boxStyle = {
        border: "1px solid #EBEBEB",
        padding: 0,
        marginLeft:"15px",
    }
    const ImgStyle = {
        maxWidth: "94px",
        height: "auto",
        padding: 0,
        width:"auto !important"

    }
    return (
        <Grid container sx={{margin: "30px 0", alignItems: "center", justifyContent: "center"}}>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Typography variant='h1'>Pași necesari refinanțare</Typography>
                <Typography variant="p" component="p" sx={{flexGrow: 1,textAlign:"center"}}>
                    Află cât vei plati după refinanțare. Completează formularul pentru a primi o analiză gratuită.
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Grid container sx={{margin: "30px 0", alignItems: "center", justifyContent: "space-between"}}>
                    <Grid item xs={6} md={3}>
                        <Box sx={boxStyle}>
                            <picture>
                                <source srcSet={`${textBoxImage2xWebP} 1x`} type="image/webp" />
                                <source srcSet={`${textBoxImage2xPNG} 1x`} type="image/png" />
                                <img width="94" height="94" style={ImgStyle} src={textBoxImage2xPNG} srcSet={` ${textBoxImage2xPNG}1x`}  alt={"form input icon"}/>
                            </picture>
                            <Typography sx={{fontWeight:700, color:"black",marginBottom:"10px"}}>Ce trebuie sa faci?</Typography>
                            <Typography variant="p" component="p" sx={pStyle}>
                                Completează formularul cu datele personale pentru a putea începe procesul de analiză
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        < Box sx={boxStyle}>
                            <picture>
                                <source srcSet={`${diagramImage2xWebP} 1x`} type="image/webp" />
                                <source srcSet={`${diagramImage2xPNG} 1x`} type="image/png" />
                                <img width="94" height="94" style={ImgStyle} src={diagramImage2xWebP} srcSet={`${diagramImage2xWebP} 1x`}   alt={"loading input icon"} />
                            </picture>
                            <Typography sx={{fontWeight:700, color:"black",marginBottom:"10px"}}>Venim noi la tine!</Typography>
                            <Typography variant="p" component="p" sx={pStyle}>
                                Nu ai timp sa ajungi la bancă ? Ne deplasăm noi la tine ! Peste 70 agenți în toată țara.
                            </Typography>
                        </Box>
                    </Grid>


                        <Grid item xs={6} md={3}>
                            < Box sx={boxStyle}>
                                <picture>
                                    <source srcSet={`${targetBlueImageWebP} 1x`} type="image/webp" />
                                    <source srcSet={`${targetBlueImage2xPNG} 1x`} type="image/png" />
                                    <img width="94" height="94" style={ImgStyle} src={targetBlueImage2xPNG} srcSet={`${targetBlueImage2xPNG} 1x`}    alt={"target input icon"}/>
                                </picture>
                                <Typography sx={{fontWeight:700, color:"black",marginBottom:"10px"}}>Îti găsim oferta potrivită.</Typography>
                                <Typography variant="p" component="p" sx={pStyle}>
                                    Căutăm cele mai bune oferte potrivite pentru tine. Dobândă fixă 5.9%
                                </Typography>
                            </Box>
                        </Grid>
                    <Grid item xs={6} md={3}>
                        <Box sx={boxStyle}>
                            <picture>
                                <source srcSet={`${calendarBlueImage2xWebP} 1x`} type="image/webp" />
                                <source srcSet={`${calendarBlueImage2xPNG} 1x`} type="image/png" />
                                <img width="94" height="94" style={ImgStyle} src={calendarBlueImage2xPNG} srcSet={`${calendarBlueImage2xPNG} 1x`}   alt={"calendar input icon"} />
                            </picture>
                            <Typography sx={{fontWeight:700, color:"black",marginBottom:"10px"}}>Cât durează?</Typography>
                            <Typography variant="p" component="p" sx={pStyle}>
                                De la semnarea contractului, depinde de procedurile bancii ( aprox. 3 săptămâni)
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const MapSection = () => {
    const ImgStyle = {
        maxWidth: "100%",
        height: "auto",
        padding: 0
    }
    return (
        <Grid container spacing={2} sx={{margin: "30px 0"}}>
            <Grid item xs={12} md={12}>
                <Typography variant='h1'>Nu lăsați problemele financiare să vă copleșească!</Typography>
                <Typography variant="p" component="p" sx={{flexGrow: 1}}>Descoperiți cum refinanțare.online vă poate ajuta să înfruntați și să depășiți dificultățile financiare. Începeți astăzi călătoria către o viață financiară mai bună!</Typography>
                <picture>
                    <source srcSet={`${mapImageWebP} 1x, ${mapImage2xWebP} 2x`} type="image/webp" />
                    <source srcSet={`${mapImagePNG} 1x, ${mapImage2xPNG} 2x`} type="image/png" />
                    <img alt={"harta cu locatii cu agenti"} width="1152" height="648" style={ImgStyle} src={mapImagePNG} srcSet={`${mapImagePNG} 1x, ${mapImage2xPNG} 2x`}  />
                </picture>
            </Grid>

            <Grid container spacing={2} sx={{margin: "30px 0"}}>
                <Grid item sx={{flexGrow:1}}>
                    <ul style={{textAlign:'left',listStyle:'none'}} >
                        <li>Timisoara </li>
                        <li>Caransebes</li>
                        <li>Arad</li>
                        <li>Oradea</li>
                    </ul>
                </Grid>
                <Grid item sx={{flexGrow:1}}>
                    <ul style={{textAlign:'left',listStyle:'none'}} >
                        <li>Drobeta Turnu Severin</li>
                        <li>Targu Jiu</li>
                        <li>Craiova</li>
                        <li>Ramnicu Valcea</li>
                    </ul>
                </Grid>
                <Grid item sx={{flexGrow:1}}>
                    <ul style={{textAlign:'left',listStyle:'none'}} >
                        <li>Deva</li>
                        <li>Hunedoara</li>
                        <li>Cluj Napoca</li>
                        <li>Turda</li>
                    </ul>
                </Grid>
                <Grid item sx={{flexGrow:1}}>
                    <ul style={{textAlign:'left',listStyle:'none'}} >
                        <li>Alba Iulia</li>
                        <li>Sibiu</li>
                        <li>Satu Mare</li>
                        <li>Brașov</li>
                    </ul>
                </Grid>
                <Grid item sx={{flexGrow:1}}>
                    <ul style={{textAlign:'left',listStyle:'none'}} >
                        <li>Ploiești   </li>
                        <li>Pitesti</li>
                        <li>București</li>
                        <li>Iași</li>
                    </ul>
                </Grid>
            </Grid>
        </Grid>
    )
}


function Home() {
    const theme = useTheme();

    const [mapSectionVisible, setMapSectionVisible] = useState(false);
    const [contactFormVisible, setContactFormVisible] = useState(false);

    const mapSectionRef = useRef(null);
    const handleIntersection = (entries, observer) => {
        entries.forEach(entry => {
                setMapSectionVisible(true);
                setContactFormVisible(true);
            })
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0 // Adjust the threshold as needed
        });

        if (mapSectionRef.current) observer.observe(mapSectionRef.current);
        return () => {
            if (mapSectionRef.current) observer.unobserve(mapSectionRef.current);

        };
    }, []);
    return (
        <div className="App" sx={{backgroundColor: theme.palette.background}}>
            <Container >
                <TextSection/>
            </Container>
            <Container id="oferta" sx={{background: theme.palette.secondary.main, justifyContent:"center",padding:"30px"}} maxWidth={"100%"}>
                <CreditCalculator/>
            </Container>
            <Container id="pasi" maxWidth="lg" ref={mapSectionRef}>
                <StepSection/>
            </Container>
            <Container  id="pch" sx={{background: theme.palette.secondary.main, justifyContent:"center",padding:"30px"}} maxWidth={"100%"}>
                <PromotionalChunk/>
            </Container>
            <Container  id="harta" maxWidth="lg"  ref={mapSectionRef} >
                {mapSectionVisible && <MapSection/>}
            </Container>
        </div>

    );
}

export default Home;
