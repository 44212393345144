import React, {useEffect, useState} from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button, Grid, Checkbox, FormControlLabel, Container, Typography, Modal, Alert
} from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const CreditCalculator = () => {
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleAlertClose = () => {
        setSuccessAlert(false);
        setErrorAlert(false);

    };

    const [formData, setFormData] = useState({
        ref: 'calculator',
        name: '',
        email: '',
        phone: '',
        acord: false,
        initialCreditValue: '',
        currentCreditValue: '',
        startYear: '',
        remainingYears: '',
        currentInstallment: '',
        monthlyInstallment: '',
        currency: '',
    });



    const [wasFormSubmitted, setWasFormSubmitted] = useState(false);


    const getFinalFormVisibility = () => {
        if (wasFormSubmitted) {
            return 'none';
        }
        return 'initial';
    }
    const getResultsVisibility = () => {
        if (wasFormSubmitted) {
            return 'initial';
        }
        return 'none';
    }

    // const toggleElements = () => {
    //     if (wasFormSubmitted) {
    //         setWasFormSubmitted(false);
    //     } else {
    //         setWasFormSubmitted(true);
    //     }
    // }

    const closeModal = () => {
        setModalOpen(false);
    }
    const openModal = () => {
        setModalOpen(true);
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        console.log(formData);
    };
    useEffect(() => {
        fetch('/get-token.php')  // Adjust the URL based on your server configuration
            .then(response => response.text())
            .then(token => formData.jwtToken = token)
            .catch(error => console.error('Error fetching token:', error));
    }, []);

    const submitVisitorDetails = async (e) => {
        // Validation for visitor details
        // e.preventDefault();
        try {
            setIsSubmitting(true);
            const response = await axios.post('/submit-form.php', {
                ...formData,
                jwt_token: formData.jwtToken,
            });

            if (response.data === 'Email sent successfully') {
                console.log('Form data submitted successfully.');
                setSuccessAlert("Formulat trimis cu succes!");
                setIsSubmitting(false);
            } else {
                console.log('Error submitting form data.');
                setErrorAlert("A aparut o eroare. Te rugam sa incerci din nou mai tarziu.");
                setIsSubmitting(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrorAlert("A aparut o eroare. Te rugam sa incerci din nou mai tarziu.");
            setIsSubmitting(false);
        }
    };

    const calculateMonthlyInstallment = () => {
        // Validation
        if (
            isNaN(formData.initialCreditValue) ||
            isNaN(formData.currentCreditValue) ||
            isNaN(formData.startYear) ||
            isNaN(formData.remainingYears) ||
            isNaN(formData.currentInstallment) ||
            formData.initialCreditValue < 0 ||
            formData.currentCreditValue < 0 ||
            formData.startYear < 0 ||
            formData.remainingYears < 0 ||
            formData.currentInstallment < 0
        ) {
            alert('Te rugam sa completezi toate campurile.');
            return;
        }
        const interestRate = 5.99 // 8% annual interest rate (you can change this as needed)
        // const totalCredit = formData.initialCreditValue - formData.currentCreditValue;
        // const monthsRemaining = formData.remainingYears * 12;
        // const monthlyInterestRate = interestRate / 12;
        const monthlyInstallment = ((100 + (interestRate * formData.remainingYears)) / 100 * formData.currentCreditValue) / formData.remainingYears / 12;

        formData.monthlyInstallment = monthlyInstallment.toFixed(2);

    };

    const resetEverything = () => {
        setWasFormSubmitted(false);
        closeModal();
    }
    const submitFormAndShowResult = () => {
        submitVisitorDetails();
        calculateMonthlyInstallment();
        setWasFormSubmitted(true);
    }
    const validateInitialInputAndOpenModal = () => {
        if (isNaN(formData.initialCreditValue) || formData.initialCreditValue < 0 || !formData.acord) {
            alert('Te rugam sa introduci o valoare pozitiva pentru creditul initial.');
            return;
        }
        openModal();
    }

    return (
        <Grid container spacing={2} sx={{
            margin: "30px auto", padding: "10px 10rem 30px 10rem ", background: "white", border: "3px solid #1AA9B4", borderRadius: "5px",// Media query for screens with width less than a certain threshold (e.g., 600px)
            "@media (max-width: 600px)": {
                margin: 0,      // Remove margin
                padding: "15px",     // Remove padding
            },
        }} maxWidth={"lg"}>
            <Grid item xs={12} md={12} sx={{textAlign: "center", padding: "0 !important"}}>
                <Typography variant='h1'>Calculează noua ta rată!</Typography>
                <Typography variant="p" component="p" sx={{flexGrow: 1, textAlign: "center"}}>
                    Află cât vei plăti după refinanțare. Completează formularul pentru a primi o analiză gratuită.
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={2}>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel>Moneda</InputLabel>
                            <Select
                                value={formData.currency}
                                onChange={handleInputChange}
                                classes={{
                                    select: "MuiSelect-select",
                                }}
                                name={"currency"} sx={{flexGrow: 1, "& select": {background: "white"}}}>
                                <MenuItem value="EUR" style={{background: "white"}}>EUR</MenuItem>

                                <MenuItem value="RON" style={{background: "white"}}>RON</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <TextField
                            label="Valoare initiala credit"
                            type="number"
                            value={formData.initialCreditValue}
                            onChange={handleInputChange}
                            name={"initialCreditValue"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} md={5}>

                        <TextField
                            label="Valoare curenta credit"
                            type="number"
                            value={formData.currentCreditValue}
                            onChange={handleInputChange}
                            name={"currentCreditValue"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <TextField
                            label="An incepere credit"
                            type="number"
                            value={formData.tartYear}
                            onChange={handleInputChange}
                            name={"startYear"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <TextField
                            label="Ani ramasi credit"
                            type="number"
                            value={formData.remainingYears}
                            onChange={handleInputChange}
                            name={"remainingYears"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <TextField
                            label="Valoare rata curenta"
                            type="number"
                            value={formData.currentInstallment}
                            onChange={handleInputChange}
                            name={"currentInstallment"}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Container>
                    <FormControlLabel required control={<Checkbox name={'acord'} value="true" onChange={handleInputChange}/>} label={<Typography>
                        Sunt de acord cu termeni și condiții site-ului cât și politica de confidențialitate.
                    </Typography>}/>
                </Container>
                <Container>
                    <Button onClick={validateInitialInputAndOpenModal} variant="contained" color="primary"
                            sx={{marginTop: "30px", fontSize: "14px", color: "white", textTransform: "capitalize", padding: "15px 45px", fontWeight: 700}}>
                        Calculează
                    </Button>
                    <Modal open={modalOpen}>

                        <Container sx={{marginTop: "30px", fontSize: "14px", background: "white", color: "black", padding: "15px 45px", fontWeight: 700}}>
                            <Grid item xs={12} sx={{display: "flex", flexWrap: "nowrap", width: "100%", justifyContent: "space-between", flexDirection: "row-reverse"}}>
                                <Button sx={{float: "right"}} variant="contained" onClick={resetEverything}><CloseIcon sx={{color: 'white'}}/></Button>
                                {successAlert && (
                                    <Alert severity="success" onClose={handleAlertClose} sx={{flexGrow: 1, marginRight: "30px"}}>
                                        {successAlert}
                                    </Alert>
                                )}
                                {errorAlert && (
                                    <Alert severity="error" onClose={handleAlertClose} sx={{flexGrow: 1, marginRight: "30px"}}>
                                        {errorAlert}
                                    </Alert>
                                )}

                            </Grid>
                            <Grid container spacing={2} sx={{display: getFinalFormVisibility()}}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant='h1' sx={{textAlign: "center"}}>Introduceti datele dvs.</Typography>
                                    <Typography component="p" sx={{flexGrow: 1, textAlign: "center"}}>Completati formularul cu datele dvs. de contact. In cel mai scurt timp, veti fi contactat de un
                                        reprezentant.</Typography>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField label="Nume Prenume" id={'name'} name={'name'} autoComplete={'name'} variant="outlined" fullWidth required onChange={handleInputChange}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField inputProps={{
                                            pattern: '^[0-9]*$', // This pattern enforces numeric input
                                            inputMode: 'numeric', // This hints to mobile devices to show a numeric keyboard
                                        }} label="Telefon" id={'phone'} autoComplete={'tel-national'} name={'phone'} variant="outlined" fullWidth required onChange={handleInputChange}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label="E-mail" id={'email'} name={'email'} autoComplete={'email'} variant="outlined" fullWidth required type={"email"} onChange={handleInputChange}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{justifyContent: "center", display: "flex"}}>
                                    <input type={'hidden'} name={'jwt_token'} id={'jwt_token'} value={formData.jwtToken}/>
                                    <Button  disabled={isSubmitting} type="submit" variant="contained" color="primary" onClick={submitFormAndShowResult}
                                            sx={{color: 'white', fontWeight: "700", padding: "15px 30px", margin: "30px 0 "}}>
                                        Vezi rezultatul
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{display: getResultsVisibility()}}>
                                <Grid item xs={12} md={12} sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <Typography variant='h1' sx={{textAlign: "center"}}>Rezultatul evaluarii</Typography>
                                    <Typography variant="body2" align="center" sx={{marginTop: '10px'}}>
                                        Rata noua calculata: <Typography
                                        sx={{fontFamily: "Consolas, sans-serif", color: "black", fontWeight: 700, fontSize: "42px"}}>{formData.monthlyInstallment} {formData.currency}</Typography>
                                    </Typography>
                                    <Typography sx={{fontSize: "12px"}}>
                                        Rezultatele oferite de acest calculator sunt estimate și au un caracter informativ.
                                    </Typography>
                                    <Typography sx={{fontSize: "12px"}}>
                                        Suma exactă a ratei lunare poate varia în funcție de condițiile oferite de instituția
                                        financiară și de alte costuri sau comisioane asociate.
                                    </Typography>
                                    <Button variant="contained" color="primary" onClick={closeModal} sx={{margin: '30px 0', textTransform: 'uppercase', color: "white"}} >
                                        Inchide
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>

                    </Modal>
                </Container>
            </Grid>
        </Grid>
    );
};

export default CreditCalculator;
