// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from '@mui/material/styles';
import { CssBaseline} from '@mui/material';
import reportWebVitals from './reportWebVitals';
import getTheme from './theme';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from "./Home";
import FinantareStartUp from "./FinantareStartUp";
import Header    from "./Header";
import Footer from "./Footer";

const rootElement = document.getElementById('root');

const Root = () => {

    const theme = getTheme('light');

    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <BrowserRouter>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/finantare-start-up" element={<FinantareStartUp />} />
                    </Routes>

                    <Footer/>
                </BrowserRouter>
            </ThemeProvider>
        </React.StrictMode>
    );
};

ReactDOM.createRoot(rootElement).render(<Root/>);
reportWebVitals();
