import React, { useEffect, useState } from 'react';
import {Grid, TextField, Checkbox, Button, FormControlLabel, Typography, Alert} from '@mui/material';
import axios from 'axios';
const ContactForm = () => {
    const [jwtToken, setJwtToken] = useState('');
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const handleAlertClose = () => {
        setSuccessAlert(false);
        setErrorAlert(false);
    };
    const [formData, setFormData] = useState({
        ref: 'contact',
        name: '',
        email: '',
        phone: '',
    });
    useEffect(() => {
        fetch('/get-token.php')  // Adjust the URL based on your server configuration
            .then(response => response.text())
            .then(token => setJwtToken(token))
            .catch(error => console.error('Error fetching token:', error));
    }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isHuman = true;

        setIsSubmitting(true);
        if (isHuman) {
            try {
                const response = await axios.post('/submit-form.php', {
                    ...formData,
                    jwt_token: jwtToken,
                });

                if (response.data === 'Email sent successfully') {
                    console.log('Form data submitted successfully.');
                    setSuccessAlert("Formular trimis cu succes!");
                    // Handle success or UI changes
                } else {
                    console.error('Error submitting form:', response.data);
                    setErrorAlert("A aparut o eroare. Te rugam sa incerci din nou mai tarziu.");
                    // Handle error or UI changes
                    setIsSubmitting(false);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                setErrorAlert("A aparut o eroare. Te rugam sa incerci din nou mai tarziu.");
                // Handle error or UI changes
                // setIsSubmitting(false);
            }
        } else {
            console.log('Please complete the hCaptcha challenge.');
            setErrorAlert("A aparut o eroare. Te rugam sa incerci din nou mai tarziu.");
            // setIsSubmitting(false);
        }
    };

    const isPhoneInvalid = !/^[0-9]*$/.test(formData.phone);

    return (
        <form onSubmit={handleSubmit} id={"contact"}>
            <Grid container spacing={2} sx={{maxWidth: "100vw", '@media (max-width: 768px)': { paddingLeft: '16px' } }}>
                    <Grid item xs={12} md={12}>
                        <Typography variant='h1'>Formular refinanțare</Typography>
                        <Typography variant="p" component="p" sx={{flexGrow: 1,textAlign:"center"}}>Completează formularul cu date personale. În maxim 24 de ore ești contactat de un reprezentant.</Typography>
                    </Grid>
                {successAlert && (
                    <Alert severity="success" id="submit-success" onClose={handleAlertClose} sx={{flexGrow: 1}}>
                        {successAlert}
                    </Alert>
                )}
                {errorAlert && (
                    <Alert severity="error" onClose={handleAlertClose} sx={{flexGrow: 1}}>
                        {errorAlert}
                    </Alert>
                )}
                <Grid item xs={12}>
                    <TextField label="Nume Prenume" id={'name'} name={'name'} autoComplete={'name'} variant="outlined" fullWidth required  onChange={handleInputChange}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Telefon" id={'phone'}  inputProps={{
                        pattern: '^[0-9]*$', // This pattern enforces numeric input
                        inputMode: 'numeric', // This hints to mobile devices to show a numeric keyboard
                    }}
                               autoComplete={'tel-national'}
                               name={'phone'}
                               variant="outlined"
                               fullWidth
                               required
                               onChange={handleInputChange}
                               error={isPhoneInvalid}
                               helperText={isPhoneInvalid ? 'Numarul de telefon trebuie sa contina doar cifre' : ''}

                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField label="E-mail" id={'email'} name={'email'}  autoComplete={'email'} variant="outlined" fullWidth required  type={"email"} onChange={handleInputChange}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox required />}
                        label="Sunt de acord cu termeni și condițiile site-ului cât și cu politica de confidențialitate."
                        id={'termeni'}
                        name={'termeni'}
                        onChange={handleInputChange}
                    />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Sunt de acord sa primesc e-mailuri din partea voastră."
                        id={'newsletter'}
                        name={'newsletter'}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <input type={'hidden'} name={'jwt_token'} id={'jwt_token'} value={jwtToken}/>
                    <Button type="submit" variant="contained" color="primary" sx={{color:'white',fontWeight:"700", padding:"15px 30px", marginBottom:'60px'}}  disabled={isSubmitting}>
                        Trimite
                    </Button>

                </Grid>
            </Grid>
        </form>
    );
};

export default ContactForm;
