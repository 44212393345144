import React from 'react';
import { Link } from 'react-router-dom';
import {List, ListItemText} from "@mui/material";
import { useMediaQuery } from '@mui/material';

const navigationLinks = [
    { label: 'Despre', to: '#' },
    { label: 'Informații', to: '/#pasi' },
    { label: 'Avantaje', to: '/#oferta' },
    { label: 'Finanțare StartUP', to: '/finantare-start-up' },
    { label: 'Ofertă', to: '/#oferta' },
    { label: 'Contact', to: '/#contact' },
];


const NavigationMenu = (data) => {
    const navigationElements = [];

    const ShouldShow = (visibility) => {
        let isMobile =  useMediaQuery('(max-width:600px)');
        return visibility === 'sm' ? isMobile : !isMobile;
    }

    const handleScroll = (event) => {
        //if uri contains '#', scroll to element, else redirect to uri
        let href = event.currentTarget.href;
        if (href.includes('#')) {
            const targetElement = document.getElementById(href.split('#')[1]);
            if (targetElement) {
                targetElement.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        }

    }
    for (let index = 0; index < navigationLinks.length; index++) {
        const link = navigationLinks[index];
        // debugger;
        navigationElements.push(
            <ListItemText key={index} >
                <Link to={link.to} style={{textDecoration:'none',fontWeight:"700",color:"black", padding:"0 10px"}} onClick={handleScroll}>{link.label}</Link>
            </ListItemText>
        );
    }
    if(ShouldShow(data.visibility)){
        return (
            <List style={{display: "flex",flexWrap:"wrap", justifyContent: "space-between", alignItems: "center"}}>
                {navigationElements}
                {data.children}
            </List>
        );
    }
};

export default NavigationMenu;
