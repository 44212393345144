import React from 'react';
import {Button} from "@mui/material";

const ScrollButton = (data) => {
    const scrollToElement = () => {
        const element = document.getElementById('contact');
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    return (
        <Button onClick={scrollToElement} variant="contained" color="primary" sx={data.sx}>
            Refinanțează
        </Button>
    );
};

export default ScrollButton;
