import React, {useState} from 'react';
import {AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, Container, ListItemText} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logoSrcPNG from './images/logo.png';
import logoSrcWEBP from './images/logo.webp';
import NavMenu from "./NavMenu";
import {Link} from "react-router-dom";
import ButtonWithScroll from "./ButtonWithScroll";

const Header = () => {
    const theme = useTheme();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    return (
        <Container maxWidth="lg" sx={{padding: "0 !important"}}>
            <AppBar position="static" sx={{backgroundColor: theme.palette.background}} elevation={0}>
                <Toolbar sx={{padding: '20px 0 !important'}}>
                    {/* Logo */}
                    <Typography variant="h6" component="div" sx={{flexGrow: 1, textAlign: 'left'}}>
                        <Link to="/">
                            <picture>
                                <source srcSet={`${logoSrcWEBP} 1x`} type="image/webp"/>
                                <source srcSet={`${logoSrcPNG} 1x`} type="image/png"/>
                                <img src={logoSrcPNG} srcSet={`${logoSrcPNG} 1x`} width="279" height="60" alt={"logo"}/>
                            </picture>
                        </Link>
                    </Typography>

                    {/* Hamburger Menu Icon */}
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleMenu}
                        sx={{display: {sm: 'none'},paddingRight:"30px"}}
                    >
                        <MenuIcon/>
                    </IconButton>

                    <NavMenu visibility={'md'}>
                        <ListItemText>
                            <ButtonWithScroll sx={{color: 'white'}}/>
                        </ListItemText>
                    </NavMenu>
                </Toolbar>
            </AppBar>

            {/* Mobile Navigation Drawer */}
            <Drawer anchor="right" open={isMenuOpen} onClose={toggleMenu} sx={{
                "& .MuiDrawer-paper": {background: "white"},
                "& .MuiListItemText-root": {
                    minWidth: '200px'
                },
                backgroundColor: "#00000042"
            }}>
                <List sx={{width: 250}}>
                    <NavMenu visibility={'sm'}/>
                    <ListItem button component="a" href="#contact" onClick={toggleMenu}>
                        <ButtonWithScroll sx={{color: 'white',width:'100%'}}/>
                    </ListItem>
                </List>
                {/* Apply a background color with opacity to the Paper component */}
            </Drawer>
        </Container>
    );
};

export default Header;
