import React, {useEffect, useState, useRef } from 'react';
import {Container, Grid, Typography, Box} from '@mui/material';

const PromotionalChunk = () => {


    const defaultConfig = {
        title: "Se anunță o vară plină de vești bune!",
        subtitle: "Începând de astazi, 12.06.2023, micșoram dobânda fixă pentru creditul ipotecar cu preț dinamic!",
        secondHeading: "Care este noua oferta de dobanda fixa?",
        firstParagraph: "<p>5,9% fix 5Y și IRCC + 2.4% din anul al 6-lea pentru condiții îndeplinite; </p><p>7,5% fix 5Y și IRCC + 2.6% din anul 6-lea pentru condiții neîndeplinite;</p><p>Restul condițiilor rămân neschimbate.</p>",
        showBigText: true,
        bigText: "5.9%",
        secondaryParagraph: "Astfel, competitivitatea noii oferte UniCredit cu dobândă fixă de",
        thirdParagraph: "ne plasează în continuare în Top pe piața bancara.",
        thirdHeading: "Reamintim Campania in desfasurare cu beneficii suplimentare pentru clienti:",
        benefits: {
            0: {
                title: "2000 RON", content: "premiu în bani cu valoare neta, pentru acoperirea costurilor conexe împrumutului;"
            },
            1: {
                title: "50%", content: "din contravaloarea primei de asigurare anuale, pentru primul an, aferente poliței facultative de locuință My Home achitata de către Banca."
            }
        }
    };

    const [config, setConfig] = useState(null);
    const configFetchedRef = useRef(false);
    const elementRef = useRef(null);
    const fetchConfigFromGoogleSheet = async () => {
        try {
            // Check if the config is already cached in local storage
            const cachedConfig = JSON.parse(localStorage.getItem('promotional_chunk_config'));
            if (cachedConfig && Date.now() - cachedConfig.timestamp < 24 * 60 * 60 * 1000) { //24 hours lifetime
                // Use the cached config if available
                setConfig(cachedConfig.data);
            } else {
                // Fetch config from Google Sheets
                const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vTgMDj9GAG_X23DBFlTETnvUp0yU21YiRt7qInEilwWCHBDswgUM2gnRJsNDn3H-FAnRsWgYvgxQeGO/pub?output=tsv');
                const csvData = await response.text();
                const fetchedConfig = parseCsvToConfig(csvData);

                // Combine the fetched config with the default config to handle fallback
                const mergedConfig = {...defaultConfig, ...fetchedConfig};
                setConfig(mergedConfig);

                // Cache the merged config in local storage
                const cacheData = {
                    timestamp: Date.now(),
                    data: mergedConfig,
                };
                localStorage.setItem('promotional_chunk_config', JSON.stringify(cacheData));
            }
        } catch (error) {
            console.error('Error fetching config from Google Sheets:', error);

            // If fetching fails, use the default config
            setConfig(defaultConfig);
        }
    };
    useEffect(() => {

        // Create an intersection observer
        const observer = new IntersectionObserver((entries) => {
            if (!configFetchedRef.current && entries[0].isIntersecting) {
                // If the element is in view and config has not been fetched yet
                configFetchedRef.current = true;
                fetchConfigFromGoogleSheet();
            }
        });

        // Start observing the element
        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        // Cleanup the observer when the component unmounts
        return () => {
            observer.disconnect();
        };
    }, []);



    if (config === null) {
        return <div ref={elementRef} >Se incarca...</div>;
    }

    // Check if the config has been fetched and is not null
    const isConfigFetched = configFetchedRef.current && config !== null;


    return (<Container maxWidth="lg" id={"oferta"} >
            <div  >
                {isConfigFetched ? (
            <Grid container spacing={2} sx={{paddingTop: '50px', paddingBottom: "30px"}}>
                <Grid item xs={0} md={1}></Grid>
                <Grid item xs={12} md={10}>
                    <Typography variant='h1'>{config.title}</Typography>
                    <Typography variant="p" component="p" sx={{flexGrow: 1, textAlign: "center"}}>{config.subtitle}</Typography>
                    <Typography variant='h2' sx={{textAlign: "center"}}>{config.secondHeading}</Typography>
                    <Typography className={"first"} sx={{"> p": {margin: 0}, flexGrow: 1, marginBottom: "30px", textAlign: "center"}}
                                dangerouslySetInnerHTML={{__html: config.firstParagraph}}></Typography>
                    <Grid container sx={{alignItems: "center", justifyContent: "center", marginBottom: "30px"}}>
                        <Grid item xs={12} md={8} sx={{}} sx={{display: (config.secondaryParagraph === "" && config.thirdParagraph ==="")?"none":"inherit"}}>
                            <Typography className={"second"} sx={{"> p": {margin: 0}, flexGrow: 1, textAlign: "left"}}>{config.showBigText ? config.secondaryParagraph : ""} </Typography>
                            <Typography className={"third"} sx={{"> p": {margin: 0}, flexGrow: 1, textAlign: "left"}}>{config.showBigText ? config.thirdParagraph : ""}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{}}>
                            <span style={{color: "#1AA9B4", fontSize: '74px', fontWeight: 900}}>{config.bigText}</span>
                        </Grid>
                    </Grid>

                    <Typography variant='h2' sx={{textAlign: "center", color: '#1AA9B4', marginBottom: "15px"}}>{config.thirdHeading}</Typography>
                    <Grid container spacing={2} sx={{paddingTop: '50px', alignItems: "stretch"}}>
                        {Object.keys(config.benefits).map((key) => {
                            return (
                                <React.Fragment key={key}>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{
                                            backgroundColor: 'white',
                                            border: '1px solid #1AA9B4',
                                            padding: "15px 0",
                                            margin: '5px',
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between"
                                        }}>
                                            <Typography  sx={{marginBottom: "10px", color: "#1AA9B4", fontSize: "24px", fontWeight: 700}}>{config.benefits[key].title}</Typography>
                                            <Typography variant="p" component="p"
                                                        sx={{flexGrow: 1, color: "#1AA9B4", padding: "0 15px", textAlign: 'center', marginBottom: 0}}>{config.benefits[key].content}</Typography>
                                        </Box>
                                    </Grid>
                                </React.Fragment>)
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={0} md={1}></Grid>
            </Grid>
                ) : (
                    <div>Se incarca...</div>
                )}
            </div>
        </Container>
    );
};

export default PromotionalChunk;
// Helper function to parse CSV data into a config object
const parseCsvToConfig = (csvData) => {
    // Implement your logic to parse the CSV data and convert it into the config object
    // For example, you can use a CSV parsing library or do manual parsing

    // Sample manual parsing (assuming the CSV data has a specific format)
    const lines = csvData.split('\r\n');
    const config = {};
    lines.forEach((line, index) => {
        const [key, value] = line.split('\t');
        config[key] = value;
    });

    try {
        config.benefits = JSON.parse(config.benefits.replace(/(\w+)(?=:)/g, '"$1"').replace(/'/g, '"'));
    } catch (e) {
        console.log(e);
        config.benefits = {};
    } finally {
    }
    return config;
};
