// theme.js
import { createTheme } from '@mui/material/styles';

const getTheme = (mode) => {
    const commonColors = {
        main: '#41a8b4',
        secondary: '#ebfdff',
    };

    const lightColors = {
        ...commonColors,
        background: '#ffffff',
    };

    const darkColors = {
        ...commonColors,
        background: '#424242',
    };

    // CSS variables


    return createTheme({
        palette: {
            mode: mode || 'light',
            primary: {
                main: commonColors.main
            },
            secondary: {
                main: commonColors.secondary,
            },
            background: mode === 'light' ? lightColors.background : darkColors.background,
        },
        typography: {
            // Define typography here if needed
            fontFamily: 'Nunito',
            fontSize: 14,
            color: '#616161',
            a:{
                color:commonColors.main
            },
            p:{
                textAlign:'left',
                marginBottom:'1rem'
            },
            h1:{
                fontFamily:'Poppins',
                fontSize:36,
                fontWeight:900,
                color:'#232323',
                marginBottom:'1rem'
            },
            h2:{
                fontFamily:'Poppins',
                fontSize:16,
                color:"#666",
                fontWeight:700,
                margin:'15px 0'


            },
            h4:{
                fontFamily:'Poppins',
                fontSize:14,
                color:'#232323',
                fontWeight:700,
                marginBottom:'3rem'
            }
        },

    });
};

export default getTheme;
